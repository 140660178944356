import React, { Component } from "react"
import { graphql, Link } from "gatsby";
import Img from 'gatsby-image'
import { Helmet } from "react-helmet";
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Layout from "../../components/layout";
import ReactGA from 'react-ga'
import "../../css/style.css";
import "../../css/animate.min.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";
import check from '../../images/check-icon.svg'
import InstituteTabs from "../../components/institutiontabs";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import ScrollAnimation from 'react-animate-on-scroll';
import {Animated} from "react-animated-css";
import PopupForm from "../../components/popupForm";
import cardImg from '../../images/programs/degree.svg'
import cardImg1 from '../../images/programs/start online.svg'
import cardImg2 from '../../images/programs/get your visa.svg'
import cardImg3 from '../../images/programs/completed overseas.svg'

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Countries  extends Component {
    constructor(props){
        super(props);
        this.state ={
            time: 90000000
        }
        
        this.readmoreFucnction = this.readmoreFucnction.bind(this)
        this.readmoreFucnction2 = this.readmoreFucnction2.bind(this)
        this.readmoreFucnction3 = this.readmoreFucnction3.bind(this)     
    }
    
    componentDidMount(){
        var swiper = new Swiper('.universities-swiper',  {
            slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                spaceBetween: 0,
                observer: true,
                observeParents: true,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                slidesPerColumn: 1,
                spaceBetween: 20,
                observer: true,
                observeParents: true,
            },
            // when window width is >= 640px
            768: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                spaceBetween: 10,
                observer: true,
                observeParents: true,
            }
        }
    });
    var swiper = new Swiper('.universities-swiper',  {
        slidesPerView: 3,
    slidesPerColumn: 2,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            spaceBetween: 0,
            observer: true,
            observeParents: true,
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
        },
        // when window width is >= 640px
        768: {
            slidesPerView: 3,
            slidesPerColumn: 2,
            spaceBetween: 10,
            observer: true,
            observeParents: true,
        }
    }
});
var swiper = new Swiper('.universities-swiper',  {
    slidesPerView: 3,
slidesPerColumn: 2,
spaceBetween: 10,
observer: true,
observeParents: true,
pagination: {
    el: '.swiper-pagination',
    clickable: true,
},
breakpoints: {
    // when window width is >= 320px
    320: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
    },
    // when window width is >= 480px
    480: {
        slidesPerView: 2,
        slidesPerColumn: 1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
    },
    // when window width is >= 640px
    768: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
    }
}
});
 

var swiper = new Swiper('.blogs-swiper', {
    slidesPerView:2,
    spaceBetween: 5,
    autoplay: true,
    // direction: 'vertical',
    speed: 300,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView:1,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        // when window width is >= 480px
        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        // when window width is >= 640px
        1200: {
            slidesPerView: 3,
            spaceBetween: 30,
        }
    }
});


}
readmoreFucnction=()=>{
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
}

 readmoreFucnction2=()=> {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
}

 readmoreFucnction3() {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
    }
    handlePopupWindow = () => {
        this.setState({ time: Math.random() })
    }
  render(){

    const uniArr = this.props.data.allWordpressWpUniversities.nodes
    const post = this.props.data.allWordpressPage.nodes[0];
    const metatitle = post.acf.meta_title ;
    const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = post.acf.canonical_url; 

    const cardData = [
        {
            image: cardImg, title: "Choose Your University", text: "Get admission into a business degree at one of our top-ranked universities in the UK"
        },
        {
            image: cardImg1, title: "Start Online", text: "Complete the first half of Year 1 online in just 3.5 months (2 modules in 7 weeks)  without any delays thanks to multiple intakes (intake every 2 months)"
        },
        {
            image: cardImg2, title: "Get Your Visa", text: "Complete your visa process while studying and be ready to go overseas by the end of the program"
        },
        {
            image: cardImg3, title: "Complete Overseas", text: "Progress to the overseas campus to complete the remainder of your degree and apply for post study work rights"
        }
    ]
    
   
    return( 
        <div clsss="countrie-page">
            {/* <PopupForm time={this.state.time} /> */}
            <Layout>
                <Helmet>
                <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          {(() => {if(post.acf.canonical_url != '' && post.acf.canonical_url != null) {return (<link rel="canonical" href={canonical_url} /> )} })()} 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />

                </Helmet>
                <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item">
												<a href="/countries">Countries </a>
											</li>
											<li class="breadcrumb-item active"> {post.title}</li>
										</ol>
				</div>
				</div>
                <section class="countrie-banner-sec" id="country-page-banner-section-sg">    <Animated animationIn="fadeInUp" animationOut="fadeInDown" isVisible={true}>  
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="countrie-banner-inner" >
                                    
                                    <div class="text-box wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" id="country-banner-image-container">
                                    {(() => {
                                                if(post.acf.custom_h1 != '' && post.acf.custom_h1 != null) {
                                                        return (<h1>{post.acf.custom_h1}</h1> )
                                                    } else {
                                                        return (<h1 class="default">Learn About Studying In Singapore</h1> ) 
                                                    }
                                                    })()}
       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>   </Animated> 
                </section>

                <section style={{ backgroundColor: "#fff" }} className="py-md-1">
                    <div className="container country-page-features-main-container">
                        <div className="row country-page-feature-container justify-content-center align-items-center">
                            <div className="col-md-6">
                                <ScrollAnimation
                                    animateIn='fadeInLeft'
                                    style={{ 'animation-duration': '1s' }}
                                >
           
                                    <Img 
                                        fluid={this.props.data.ferrisWheel.childImageSharp.fluid} 
                                        imgStyle={{
                                            borderRadius: "15px"
                                        }}
                                        style={{
                                            maxWidth: "500px",
                                            maxHeight: "600px"
                                        }}
                                    />
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <ScrollAnimation
                                    animateIn='fadeInRight'
                                    style={{ 'animation-duration': '1s' }}
                                >

                                    <h3>Singapore: Overview</h3>
                                    {/* <p className="country-usa-overview">
                                        The UK is known for its world-renowned universities and innovative teaching approach.  The UK education system gives students <span id="dots2">...</span><span id="more2"> the freedom to tailor their degree to their needs and a world class faculty to boost. A UK education is sure to open doors wherever you go in the world. Add to that an amazing student life experience and huge international student population making the UK an even more attractive destination. </span><a href="javascript:void(0)" onClick={() => this.readmoreFucnction2()} id="myBtn2">Read more</a>
                                    </p> */}
                                    <p className="country-usa-overview">
                                    Singapore has a world-class education system with a mix of East and West. The education system is known for its strong academic standards and innovative teaching methods. What sets Singapore apart is its location in the heart of Asia, giving students access to multiple cultures and emerging markets. The education system balances theory and practical skills so graduates are job-ready for the global market. English is the main language of instruction, making it an adaptive and thriving destination for international students.
                                    </p>
                                    <ul>
                                        <li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>2 of the top 15 universities in the world are in Singapore, making it the education hub in Asia.</li>
                                        <li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>Singapore’s education system is consistently ranked among the top globally, especially in STEM.</li>
                                        <li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>The multicultural environment and high standard of living make Singapore a student favourite worldwide.</li>
                                        <li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>According to The Business Times, about 65,400 international students were studying in Singapore by the end of April 2022. Roughly, the count increases by 10% every year.</li>
                                    </ul>
                                   
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container country-page-features-main-container">
                        <div className="row country-page-feature-container justify-content-center align-items-center">
                            <div className="col-md-6 order-first order-md-last">
                                <ScrollAnimation
                                    animateIn='fadeInLeft'
                                    style={{ 'animation-duration': '1s' }}
                                >

                                    <Img 
                                        fluid={this.props.data.bigBen.childImageSharp.fluid} 
                                        imgStyle={{
                                            borderRadius: "15px"
                                        }}
                                        style={{
                                            maxWidth: "500px",
                                            maxHeight: "600px"
                                        }}
                                    />
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <ScrollAnimation
                                    animateIn='fadeInRight'
                                    style={{ 'animation-duration': '1s' }}
                                >

                                    <h3>Cost Of Education In Singapore</h3>

                                    <ul>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>University Tuition Fees</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            Depending on the course you choose and the institution you enrol in, the annual fees start from SGD 64,000
                                            </p>
                                        </li>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Living Costs</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            The cost of living in Singapore per month for a student typically ranges between SGD 1300 and SGD 2000.
                                            </p>
                                        </li>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Scholarships and Savings with The WorldGrad</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            By completing a part of the first semester online from home with The WorldGrad, you can save up to SGD 27,200: ~SGD 11,200 in tuition fees and ~SGD 16,000 in living costs.  
                                            </p>
                                            <p>Additional merit-based scholarships of up to SGD 10,000 on overseas tuition fees are available to The WorldGrad students.</p>
                                        </li>
                                    </ul>
                                   

                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{ backgroundColor: "#fff" }} className="py-md-1">
                    <div className="container country-page-features-main-container">
                        <div className="row country-page-feature-container justify-content-center align-items-center">
                            <div className="col-md-6">
                                <ScrollAnimation
                                    animateIn='fadeInLeft'
                                    style={{ 'animation-duration': '1s' }}
                                >
            
                                    <Img 
                                        fluid={this.props.data.ukPassport.childImageSharp.fluid} 
                                        imgStyle={{
                                            borderRadius: "15px"
                                        }}
                                        // style={{
                                        //     maxWidth: "500px",
                                        //     maxHeight: "600px"
                                        // }}
                                    />
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <ScrollAnimation
                                    animateIn='fadeInRight'
                                    style={{ 'animation-duration': '1s' }}
                                >

                                    <h3>Visa Guidance for Singapore</h3>

                                    <p className="country-usa-overview-mini">
                                    To study in Singapore, you need to apply and be accepted by an approved Institute of Higher Learning (IHL). Once accepted, you can apply for a Student’s Pass through the Student’s Pass Online Application & Registration (SOLAR) system managed by the Immigration & Checkpoints Authority (ICA).
                                    </p>

                                    <ul>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Visa Requirements</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            You’ll need a valid passport (at least 3 months beyond your intended stay), a formal admission letter from your IHL, a completed eForm16, a recent passport-sized photo, and proof of financial resources to support your studies. Additional requirements include certificates of educational achievements, employment history records, accommodation details and a recent medical certificate. Minimum English language proficiency is required.
                                            </p>
                                        </li>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Timelines</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            Processing time is 10-15 days. You can apply up to 2 months before you arrive in Singapore. If approved, you will receive an In-Principle Approval (IPA) letter and your temporary Student Pass to enter Singapore. Upon arrival, you must collect your official Student Pass from the ICA centre.
                                            </p>
                                        </li>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Costs</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            tandard fees for a Singapore Study Visa are SGD 30 processing fee and SGD 60 issuance fee. Additional fees may apply for individual cases.

                                            </p>
                                            <p>Get to know more about Singapore Student Visa.</p>
                                        </li>
                                    </ul>

                                    

                                </ScrollAnimation>
                                <a
                                    class="btn-default visa-visit-btn mx-auto"
                                    href="https://www.mfa.gov.sg/Overseas-Mission/Beijing/Application-for-Students-Pass"
                                    target="_blank"
                                    onClick={() => {
                                        this.handlePopupWindow()
                                        ReactGA.event({
                                            category: "Countries",
                                            action: "Clicks",
                                            label: "Visit Visa Website"
                                        })
                                    }}
                                >
                                    Visit VISA website
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container country-page-features-main-container">
                        <div className="row country-page-feature-container justify-content-center align-items-center">
                            <div className="col-md-6 order-first order-md-last">
                                <ScrollAnimation
                                    animateIn='fadeInLeft'
                                    style={{ 'animation-duration': '1s' }}
                                >

                                    <Img 
                                        fluid={this.props.data.money.childImageSharp.fluid} 
                                        imgStyle={{
                                            borderRadius: "15px"
                                        }}
                                        style={{
                                            maxWidth: "500px",
                                            maxHeight: "600px"
                                        }}
                                    />
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <ScrollAnimation
                                    animateIn='fadeInRight'
                                    style={{ 'animation-duration': '1s' }}
                                >

                                    <h3>Employability & Careers In Singapore</h3>

                                    <ul>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Growing Industries</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            Singapore's economic growth is driven by several key industries, with the technology sector at the forefront. The city-state's information and communications technology (ICT) industry, which saw a 4.8% growth in 2023, is booming due to increased digitalisation and smart nation initiatives. Financial services, another powerhouse, experienced a 5.1% growth in the same year, bolstered by fintech innovations and a robust regulatory environment.
                                                <br />
                                                <a href="https://www.edb.gov.sg/en/why-singapore/an-economic-powerhouse.html" target="_blank">Source</a>
                                            </p>
                                        </li>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Careers</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            Some of the top career choices in Singapore are:
                                                <br />
                                                1. Software Developer
                                                <br />
                                                2. Trade Finance Officer
                                                <br />
                                                3. Human Resources Manager
                                                <br />
                                                4. Business Intelligence Analyst
                                                <br />
                                                5. Network Administrator
                                                <br />
                                            </p>
                                            
                                        </li>
                                        <li className="country-usa-overview mb-2">
                                            <span>
                                                <img className="mr-2 country-page-tick-img" src={check} alt="✓" />
                                            </span>
                                            <h4 className="mb-5" style={{ display: "inline" }}>Salary</h4>
                                            <p className="country-usa-overview-mini mt-3">
                                            The median salary of a fresh graduate in Singapore generally ranges from SGD 42,000 - 63,000
                                            </p>
                                        </li>
                                    </ul>

                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{ backgroundColor: "#fff" }} className="py-md-1">
                    <div className="container country-page-features-main-container">
                        <div className="row country-page-feature-container-last justify-content-center">
                            <div className="col-md-6">
                                <ScrollAnimation
                                    animateIn='fadeInLeft'
                                    style={{ 'animation-duration': '1s' }}
                                >
                                    <h3 className="mb-2 mb-md-4 text-center pt-4 pt-md-0"> Credit Issuing Partners </h3>
        
                                    <img
                                        src="https://theworldgrad.wpengine.com/wp-content/uploads/2023/10/pearson.jpg" alt="Pearson"
                                        class="img-fluid mb-3 d-flex mx-auto shadow p-1"
                                        style={{ borderRadius: "15px", width: "250px" }}
                                    />
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <ScrollAnimation
                                    animateIn='fadeInRight'
                                    style={{ 'animation-duration': '1s' }}
                                >

<h3>The WorldGrad Path To A Degree In Singapore</h3>

<p className="country-usa-overview"> Our Smart Programs - Global Year 1 and Global Online Accelerator Programs offer you a cost-effective path to a top-ranked Singapore university.</p>

<ul>
<li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>The WorldGrad’s Smart Programs allow students to complete the first semester of their undergraduate degree from home.</li>
<li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>From the second semester onwards, students can move to the top-ranked university campus and complete their degree.
</li>
<li className="country-usa-overview mb-3"><span><img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" /></span>The credits are issued by the University of Wollongong, Australia. </li>
</ul>

<div className="row">
							
                            <div className="col-md-6">
                                <a
                                    class="btn-default visa-visit-btn mx-auto text-center"
                                    id="GOAP-btn"
                                    href="/global-year-1-program/"
                                    onClick={() => {
                                        ReactGA.event({
                                            category: "Countries",
                                            action: "Clicks",
                                            label: "Visit Visa Website - Our Visa Guidance for Singapore",
                                        });
                                    }}>
                                    GLOBAL YEAR 1 PROGRAM
                                </a>
                                </div>
                                <div className="col-md-6">
                                <a
                                    class="btn-default visa-visit-btn mx-auto text-center"
                                    id="GOAP-btn"
                                    href="/global-online-accelerator-program/"
                                    onClick={() => {
                                        ReactGA.event({
                                            category: "Countries",
                                            action: "Clicks",
                                            label: "Visit Visa Website - Our Visa Guidance for Singapore",
                                        });
                                    }}>
                                    GLOBAL ONLINE ACCELERATOR PROGRAM
                                </a>
                            </div>
                        </div>

                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <div class="universitie-partner-sec" > */}
                    <div class="container" style={{ backgroundColor: "#f8f8f8" }}>
                        <div class="row">
                            <div class="col-12">
                                {/* <div class="title"> */}
                                    <h2 className="text-center mb-0 mt-md-4">Featured Universities In Singapore</h2>
                                {/* </div> */}
                                <Tabs>
                                    <TabPanel>
                                        <section id="dunkles" class="tab-panel">
                                            <div class="interest-explore-inner">
                                                <div class="swiper-container universities-swiper">
                                                    <div class="swiper-wrapper row justify-content-center m-0">
                                                        {
                                                            uniArr.map(university => {
                                                                return (
                                                                    <div className="col-md-4" key={university.id}>
                                                                        <Link to={`/university-detail/${university.slug}`}>
                                                                            <div class="swiper-slide" value="Open" onClick={() => {
                                                                                // this.openModal5()
                                                                                ReactGA.event({
                                                                                    category: "Institutions",
                                                                                    action: "Clicks",
                                                                                    label: `UK - ${university.title}`
                                                                                })
                                                                            }}>
                                                                                <InstituteTabs
                                                                                    ImageUrl={university.acf.banner_image ? university.acf.banner_image.source_url : (university.acf.university_thumbnail ? university.acf.university_thumbnail.source_url : null)
                                                                                    }
                                                                                    UniversityName={university.title}
                                                                                />
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                {/* </div> */}

            </Layout>
        </div>    
    )
  }
}
export default Countries;

export const WorldGradUniversities = graphql`
    {
        allWordpressPage(filter: {wordpress_id: {in: 23842}}) {
            nodes {
              title
              wordpress_id
              acf {
                 
                meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
custom_h1   
          }
        }
    }  
        allWordpressWpUniversities(filter: {acf: {country: {eq: "Singapore"}}}) {
            nodes {
                id
                slug
                title
                acf {
                    university_thumbnail {
                        source_url
                    }
                    banner_image {
						source_url
					}
                }
            }
        }
        ferrisWheel: file(relativePath: {eq: "singapore-1.jpg"}) {
            base
            childImageSharp {
                fluid (maxHeight: 600, maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bigBen: file(relativePath: {eq: "singapore-2.jpg"}) {
            base
            childImageSharp {
                fluid (maxHeight: 600, maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ukPassport: file(relativePath: {eq: "singapore-3.jpg"}) {
            base
            childImageSharp {
                fluid (maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        money: file(relativePath: {eq: "singapore-4.jpg"}) {
            base
            childImageSharp {
                fluid (maxWidth: 500, maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
